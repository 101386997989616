import { FC } from "react";
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack'
import { PageLayout } from "../../layouts/page-layout";
import { ReqCarsModel } from "../../api/toprent-api";
import { useCreateModelsCarMutation } from "../../api/use-create-cars-model-mutation";
import { ModelForm } from "../components/model-form";
import { carMapToDto } from "../../utils/car-map-to-dto";
import { usePlacesQuery } from "../../api/use-places-query";

export const ModelCreate: FC = () => {
    const { mutateAsync } = useCreateModelsCarMutation()
    const navigate = useNavigate();

    const handleSubmit = async (values: ReqCarsModel) => {
        try {
            const data = await mutateAsync(carMapToDto(values))
            enqueueSnackbar({ variant: 'success', message: 'Модель создана' });
            navigate(`/models/${data._id}`)
        } catch {
            enqueueSnackbar({ variant: 'error', message: 'Ошибка в создании модели' });
        }
    }

    const { data: places, isPending } = usePlacesQuery();

    if (isPending) return <div>Загрузка локаций</div>;

    return (
        <PageLayout title="Создание модели">
            <ModelForm
                onSubmit={handleSubmit}
                initialValues={{
                    name: { ru: '', en: '' },
                    body: { ru: '', en: '' },
                    colors: [],
                    isAllowOnlineBooking: true,
                    isPremium: false,
                    isFixedPrice: false,
                    transmission: undefined,
                    scope: [],
                    places: places?.map((item) => item.service_id),
                    status: 'CREATED',
                    wheelDrive: undefined,
                    consumption: undefined,
                    allowedPlace: undefined,
                    capacity: undefined,
                    year: undefined,
                    targets: [],
                    power: undefined,
                    minPrice: undefined,
                    minExperience: undefined,
                    minAge: undefined
                }}
            />
        </PageLayout>
    )
}