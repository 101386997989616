import { ReqCarsModel } from "../api/toprent-api"

export const carMapToDto = (obj: ReqCarsModel): ReqCarsModel => {
    return {
        allowedPlace: obj.allowedPlace,
        capacity: obj.capacity,
        consumption: obj.consumption || null,
        minAge: obj.minAge,
        minExperience: obj.minExperience,
        minPrice: obj.minPrice,
        name: obj.name,
        body: obj.body,
        places: obj.places,
        power: obj.power,
        scope: obj.scope,
        slug: obj.slug,
        isAllowOnlineBooking: obj.isAllowOnlineBooking,
        isFixedPrice: obj.isFixedPrice,
        isPremium: obj.isPremium,
        washing: obj.washing,
        engineCapacity: obj.engineCapacity || null,
        description: obj.description,
        status: obj.status,
        targets: obj.targets,
        transmission: obj.transmission,
        wheelDrive: obj.wheelDrive,
        year: obj.year,
    }
}