import { FC } from "react";
import { PageLayout } from "../../layouts/page-layout";
import { Post } from "../../api/toprent-api";
import { useCreatePostMutation } from "../../api/use-create-post-mutation";
import { useNavigate } from "react-router-dom";
import { PostsForm } from "../components/posts-form";

export const PostsCreate: FC = () => {
    const { mutateAsync: createPost } = useCreatePostMutation()
    const navigate = useNavigate();

    const handleSubmit = async (post: Post, ) => {
        if (!post.preview) return;
        const savedPost = await createPost(post)

        navigate(`/posts/${savedPost._id}`)
    }

    return (
        <PageLayout title="Создание поста">
            <PostsForm
                onSubmit={handleSubmit}
                initialValues={{
                    body: { ru: '', en: '' },
                    head: { ru: '', en: '' },
                    title: '',
                    slug: '',
                    time: { hours: undefined, minutes: undefined },
                    content: '',
                    lang: 'ru',
                    preview: '',
                    maps: [''],
                    distance: undefined,
                    status: 'DRAFT',
                }}
            />
        </PageLayout>
    )
}